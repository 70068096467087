import { MutationTree } from 'vuex';
import Product, { IProduct } from '~/std/Product';

const mutation: MutationTree<IProduct> = {
  set(state: IProduct, { product, characteristics, images }) {
    state.id = product.id
    // this.img = require('~/images/icons/delivery.svg')
    state.brand = product.brand_name
    state.name = product.name
    state.link = product.link
    state.description = product.description
    state.article = product.article
    state.code = product.code
    state.price = product.price
    state.availability = product.availability
    state.type_link = product.type_link
    state.type_name = product.type_name
    state.brand_link = product.brand_link
    state.images = images || []
    if (characteristics) state.props = characteristics || []
  },
  clear(state: IProduct) {
    const product = Product.emptyObj();
    state.id = product.id;
    // this.img = require('~/images/icons/delivery.svg')
    state.brand = product.brand;
    state.name = product.name;
    state.link = product.link;
    state.description = product.description;
    state.article = product.article
    state.code = product.code
    state.price = product.price
    state.availability = product.availability
    state.type_link = product.type_link
    state.type_name = product.type_name
    state.brand_link = product.brand_link
    state.images = []
    state.props = []
  }
};

export default mutation;
