import { ActionTree } from 'vuex';
import { StateInterface } from '../index';
import { IProduct } from '~/std/Product';

const actions: ActionTree<IProduct, StateInterface> = {
  async load({ commit }, link: string) {
    const path = `/product/${encodeURI(link)}`
    const result = await this.$axios.get(path)
    commit('set', result.data)
  },
  clear({ commit }) {
    commit('clear')
  }
}
export default actions;
